import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; // version : "1.10.1"

function useReCaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = useCallback(async () => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      console.warn("🛑 Bypassing reCAPTCHA in development mode.");
      return "dummy-dev-token"; // Fake token for dev mode
    }

    if (!executeRecaptcha) {
      console.error("executeRecaptcha not available yet.");
      return null;
    }

    return await executeRecaptcha("yourAction");
  }, [executeRecaptcha]);

  return getReCaptchaToken;
}


export default useReCaptcha;

export async function verifyReCaptcha(token){
  if (process.env.REACT_APP_NODE_ENV === "development") {
    console.warn("🛑 Skipping reCAPTCHA verification in development mode.");
    return true; // Always return true in development
  }

  if (!token) return false; // Prevent API call if token is empty

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}netre/verify-captcha`,
      {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY ?? "",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      }
    );

    const data = await response.json();
    return data.success ?? false;
  } catch (error) {
    console.error("Error verifying reCAPTCHA:", error);
    return false;
  }
}
